<template>
  <v-dialog
    :value="dialogs.changePlan"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Сменить тариф пользователю {{ username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <!-- ТАРИФ -->
          <v-col :cols="12">
            <v-btn-toggle
              v-model="plan"
              mandatory
              @change="planChanged"
            >
              <v-btn
                v-for="item in ['free', 'base', 'team', 'enterprise']"
                :key="`anty-admin-panel-change-plan-${item}`"
                :value="item"
                small
                text
                color="primary"
              >
                {{ item }}
              </v-btn>
            </v-btn-toggle>
            <!-- <v-text-field
              label="Тариф"
              dense
              outlined
              hide-details
              :spellcheck="false"
            /> -->
          </v-col>

          <!-- КОЛИЧЕСТВО ПОЛЬЗОВАТЕЛЕЙ -->
          <v-col :cols="12">
            <v-text-field
              v-model="usersLimit"
              label="Количество пользователей"
              dense
              outlined
              :spellcheck="false"
              :disabled="['free'].includes(plan)"
              :persistent-hint="true"
              :hint="hintForUsersInput"
            />
          </v-col>

          <!-- КОЛИЧЕСТВО ПРОФИЛЕЙ -->
          <v-col :cols="12">
            <v-text-field
              v-model="browserProfilesLimit"
              label="Количество профилей"
              dense
              outlined
              :spellcheck="false"
              :disabled="['team', 'base'].includes(plan)"
              type="number"
              :min="plan === 'free' ? 10 : plan === 'enterprise' ? 1000 : 10"
              :step="plan === 'free' ? 10 : plan === 'enterprise' ? 1000 : 10"
              :max="plan === 'free' ? 60 : 100000"
              :persistent-hint="true"
              :hint="hintForProfilesInput"
            />
          </v-col>

          <!-- ДОБАВИТЬ ДНЕЙ -->
          <v-col :cols="12">
            <v-text-field
              v-model="daysToAdd"
              label="Добавить дней"
              dense
              outlined
              hide-details
              :spellcheck="false"
              type="number"
            />
          </v-col>

          <!-- ДОБАВИТЬ ПЛАТЕЖ -->
          <v-col
            :cols="12"
            class="d-flex align-center flex-wrap"
          >
            <div
              style="width: 100%;"
              class="mb-2"
            >
              Добавить платеж
            </div>
            <!-- Сумма -->
            <v-text-field
              v-model="amount"
              label="Сумма"
              dense
              outlined
              hide-details
              type="number"
              class="mr-4"
            />

            <!-- ВАЛЮТА -->
            <v-btn-toggle
              v-model="currency"
              mandatory
            >
              <v-btn
                v-for="item in ['RUB', 'USD']"
                :key="`anty-admin-panel-add-payment-${item}`"
                :value="item"
                small
                text
                color="primary"
              >
                {{ item }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          @click="changeLicense"
        >
          Сменить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogChangePlan',

  data() {
    return {
      plan: '',
      usersLimit: '',
      browserProfilesLimit: '',
      subscriptionExpiration: '',
      loading: false,
      daysToAdd: 0,
      amount: 0,
      currency: 'RUB',
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      dialogs: 'users/dialogs',
    }),

    hintForUsersInput() {
      return `Начальное значение - ${this.users.forDialog.user.usersLimit}`;
    },
    hintForProfilesInput() {
      return `Начальное значение - ${this.users.forDialog.user.browserProfilesLimit}`;
    },
  },

  created() {
    this.username = this.users.forDialog.user.username;
    this.plan = this.users.forDialog.user.plan;
    this.usersLimit = this.users.forDialog.user.usersLimit;
    this.browserProfilesLimit = this.users.forDialog.user.browserProfilesLimit;
    this.subscriptionExpiration = this.users.forDialog.user.subscriptionExpiration;
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'changePlan');
    },

    planChanged(value) {
      if (value === 'free') {
        this.usersLimit = 1;
        this.browserProfilesLimit = 10;
      }
      if (value === 'base') {
        this.browserProfilesLimit = 100;
      }
      if (value === 'team') {
        this.browserProfilesLimit = 300;
      }
      if (value === 'enterprise') {
        this.browserProfilesLimit = 1000;
      }
    },

    async changeLicense() {
      try {
        this.loading = true;
        const data = {
          teamId: this.users.forDialog.user.teamId,
          plan: this.plan,
          usersLimit: this.usersLimit,
          browserProfilesLimit: this.browserProfilesLimit,
          subscriptionExpiration: this.subscriptionExpiration,
          daysToAdd: this.daysToAdd,
          paymentAmount: this.amount,
          paymentCurrency: this.currency,
        };

        const response = await this.api.patch('/admin/subscription', data);

        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('users/loadCurrentPage');
          this.$store.dispatch('main/alert', { color: 'success', message: 'Изменил' });
        }
      } catch (error) {
        //
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
