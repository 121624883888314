var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialogs.suspiciousEntrance,"width":"600","overlay-opacity":0.9,"scrollable":""},on:{"click:outside":_vm.closeDialog,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog.apply(null, arguments)}}},[_c('v-card',[_c('v-toolbar',{staticClass:"pr-0",attrs:{"height":48,"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" \"Подозрительный вход\" (Гео 2FA) "+_vm._s(_vm.users.forDialog.user.username)+" ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"items":_vm.suspiciousEntrance.suspiciousValues,"headers":_vm.cols,"items-per-page":-1,"hide-default-footer":"","item-key":"id","height":"400","fixed-header":"","loading":_vm.loading.suspiciousEntrance},scopedSlots:_vm._u([{key:"item.login_success",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.login_success)?_c('v-icon',{attrs:{"color":"green accent-4"}},[_vm._v(" mdi-checkbox-marked ")]):_c('v-icon',{attrs:{"color":"red darken-1"}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.geolocation",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.country && item.city ? ((item.country) + ", " + (item.city)) : (item.country || item.city))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.created_at && _vm.moment.utc(item.created_at).local().format('DD.MM.YYYY HH:mm'))+" ")])]}},{key:"item.os",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.os || '')+" ")])]}},{key:"footer",fn:function(){return [_c('v-data-footer',{attrs:{"options":_vm.footerOptions,"pagination":_vm.footerPagination,"items-per-page-options":[10, 30, 50]},on:{"update:options":_vm.updateFooterOptions}})]},proxy:true}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"grey"},on:{"click":_vm.closeDialog}},[_vm._v(" Закрыть ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }