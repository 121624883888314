<template>
  <v-dialog
    :value="true"
    max-width="600px"
    content-class="user-dialog-history"
    @click:outside="closeUserHistory"
    @keydown.esc="closeUserHistory"
  >
    <v-card>
      <v-card-title>
        Username Changes
      </v-card-title>

      <v-card-text>
        <v-list v-if="formattedUsernameChanges && formattedUsernameChanges.length">
          <v-list-item-group>
            <v-list-item
              v-for="change in formattedUsernameChanges"
              :key="change.id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Date: {{ change.created_at }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Old Username: {{ change.oldUsername }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  New Username: {{ change.newUsername }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Changed By: {{ change.changed_by_user.username }} ({{ change.changed_by_user.role }})
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div
          v-else
          class="d-flex align-center justify-center"
        >
          No username changes have been recorded
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="closeUserHistory"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogHistory',

  computed: {
    ...mapGetters({
      forDialog: 'users/forDialog',
    }),

    usernameChanges() {
      return this.forDialog?.user?.username_changes || [];
    },

    formattedUsernameChanges() {
      return this.usernameChanges.map((change) => ({
        ...change,
        created_at: this.formatDate(change.created_at),
      }));
    },
  },

  methods: {
    formatDate(dateString) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-GB', options);
    },

    closeUserHistory() {
      this.$store.dispatch('users/closeDialog', 'history');
    },
  },
};
</script>

<style scoped lang="scss">
.user-dialog-history.v-dialog {
  .v-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    background-color: #f5f5f5;
  }

  .v-card-text {
    padding: 16px;
  }

  .v-list-item__content .v-list-item__title {
    margin-bottom: 8px;
  }

  .v-list-item-title,
  .v-list-item-subtitle {
    font-size: 1rem;
  }

  .v-list-item-subtitle {
    color: grey;
  }

  .v-btn {
    margin-right: 16px;
  }

  .v-list-item {
    padding: 0;
  }

  .v-list-item:not(:last-child) {
    border-bottom: 1px solid darkgrey;
  }
}
</style>
