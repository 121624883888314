<template>
  <v-dialog
    :value="dialogs.changeTelegram"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Изменить ТГ пользователю {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-alert
          color="red"
          type="warning"
        >
          Убедитесь, что вы подтвердили юзера по почте
        </v-alert>
        <v-row>
          <!-- Пароль -->
          <v-col :cols="12">
            <v-text-field
              v-model="telegramName"
              label="Новый telegram пользователя "
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          :disabled="isDisableComputed"
          small
          text
          color="primary"
          :loading="loading"
          @click="change"
        >
          Сменить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogChangeTelegramName',

  data() {
    return {
      telegramName: '',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      dialogs: 'users/dialogs',
    }),

    isDisableComputed() {
      return this.telegramName.length < 1;
    },
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'changeTelegram');
    },

    async change() {
      try {
        this.loading = true;
        const data = {
          telegramName: this.telegramName,
          userId: this.users.forDialog.user.id,
        };

        const response = await this.api.post('/admin/users/change-telegram-name', data);

        if (response.status === 200) {
          this.closeDialog();
          await this.$store.dispatch('main/alert', { color: 'success', message: 'Изменил' });
        }
      } catch (error) {
        //
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
