export default {
  methods: {
    copyToClipboard(value, messageText) {
      const el = document.createElement('textarea');
      el.addEventListener('focusin', (e) => e.stopPropagation());
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$store.dispatch('main/alert', {
        color: 'success',
        message: messageText ?? 'Готово',
      });
    },
  },
};
