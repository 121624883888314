<template>
  <v-dialog
    :value="dialogs.showTransactions"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Транзакции {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-data-table
          id="affiliateMainTable"
          :items="userTransactions"
          :headers="cols"
          :items-per-page="-1"
          hide-default-footer
        >
          <!-- ДАТА -->
          <template #[`item.date`]="{item}">
            <span>
              {{ moment.parseZone(item.created_at).local().locale('ru').format("DD MMM YYYY HH:mm") }}
            </span>
          </template>

          <!-- СУММА -->
          <template #[`item.sum`]="{item}">
            {{ item.currency ? showSum(item) : item.sum }}

            <v-tooltip
              v-if="item.provider === 'cloudpayments' && canCancelSelected(item)"
              bottom
            >
              <template #activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  class="mr-2"
                  :loading="loadingCancelButton"
                  :disabled="loadingCancelButton"
                  v-on="on"
                  @click="cancelSelected(item)"
                >
                  <v-icon
                    size="18"
                  >
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Отменить</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'UsersDialogTransactions',

  data() {
    return {
      moment,
      loadingCancelButton: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
      userTransactions: 'users/userTransactions',
    }),

    cols() {
      const cols = [];

      cols.push({ text: 'Дата', value: 'date', width: 200 });
      cols.push({
        text: 'Сумма', value: 'sum', width: 200, sortable: false,
      });

      return cols;
    },
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'showTransactions');
    },

    showSum(item) {
      switch (item.currency.toUpperCase()) {
        case 'BRL':
          return item.sum.toLocaleString('ru-RU', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 });
        case 'EUR':
          return item.sum.toLocaleString('ru-RU', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 });
        case 'RUB':
          return item.sum.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', maximumFractionDigits: 2 });
        default:
          return item.sum.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
      }
    },
    canCancelSelected(item) {
      const $created = moment(item.created_at);
      const $now = moment();
      return $now.diff($created, 'hours') < 24;
    },
    cancelSelected(item) {
      this.loadingCancelButton = true;
      this.api.post('/admin/subscription/cancel-transaction', {
        transactionId: item.id,
      }).then(() => {
        this.$store.dispatch('main/alert', { color: 'success', message: 'Изменил' });
      }).finally(() => {
        this.loadingCancelButton = false;
      });
    },
  },

};
</script>
