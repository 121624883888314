var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialogs.abuse,"width":"600","scrollable":""},on:{"click:outside":_vm.closeDialog,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog.apply(null, arguments)}}},[_c('v-card',[_c('v-toolbar',{staticClass:"pr-0",attrs:{"height":48,"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Машины с абьюзом от "+_vm._s(_vm.users.forDialog.user.username)+" ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('p',[_vm._v(" Раскодировать MachineId можно "),_c('a',{attrs:{"href":"https://www.base64decode.org/","target":"_blank"}},[_vm._v("тут")])]),_c('v-text-field',{attrs:{"label":"Поиск по machineId","dense":"","solo":"","single-line":"","hide-details":"","clearable":"","clear-icon":"mdi-close","spellcheck":false},on:{"input":_vm.search},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-magnify ")])]},proxy:true}])}),_c('v-data-table',{attrs:{"id":"affiliateMainTable","items":_vm.machines,"headers":_vm.cols,"items-per-page":-1,"hide-default-footer":"","loading":_vm.loadingMachines},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.setMainAccount(item.machineId, _vm.users.forDialog.user.id)}}},on),[_vm._v(" mdi-account-cowboy-hat ")])]}}],null,true)},[_c('span',[_vm._v("Сделать основным "+_vm._s(_vm.users.forDialog.user.username)+" для этой машины")])]),_c('v-tooltip',{attrs:{"top":"","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.resetMainAccount(item.machineId)}}},on),[_vm._v(" mdi-account-cancel-outline ")])]}}],null,true)},[_c('span',[_vm._v("Вернуть основной по умолчанию")])]),(true)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","transition":false,"max-height":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":"","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.loadAbusers(item.machineId)}}},Object.assign({}, tooltip, menu)),[_vm._v(" mdi-account-details ")])]}}],null,true)},[_c('span',[_vm._v("Список аккаунтов на машине")])])]}}],null,true)},[(_vm.loadingAbusers)?_c('v-skeleton-loader',{attrs:{"max-width":"300","width":"200","type":"list-item@3"}}):_vm._e(),_c('v-list',_vm._l((_vm.abusers),function(abuser){return _c('v-list-item',{key:("abusers-list-" + (abuser.id))},[_c('v-list-item-content',[_c('v-list-item-title',{class:{'blue--text' : abuser.username.toUpperCase().includes(_vm.users.forDialog.user.username.toUpperCase()) }},[_vm._v(" "+_vm._s(abuser.username)+" ")])],1)],1)}),1)],1):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }