<template>
  <v-dialog
    :value="dialogs.add"
    width="500"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Добавить пользователя
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <!-- EMAIL -->
          <v-col :cols="12">
            <v-text-field
              v-model="email"
              label="Email"
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>

          <!-- ПАРОЛЬ -->
          <v-col :cols="12">
            <v-text-field
              v-model="password"
              label="Пароль"
              dense
              outlined
              hide-details
              :spellcheck="false"
              class="centralize-append-icon"
            >
              <template #append>
                <!-- СГЕНЕРИРОВАТЬ ПАРОЛЬ -->
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      color="grey"
                      small
                      icon
                      v-on="on"
                      @click="generatePassword"
                    >
                      <v-icon size="16">
                        mdi-key-variant
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Сгенерировать пароль</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>

          <!-- TELEGRAM NAME -->
          <v-col :cols="12">
            <v-text-field
              v-model="telegramName"
              label="Telegram"
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>
          <v-col
            v-if="userExists"
            :cols="12"
            class="py-0"
          >
            <v-alert
              text
              color="error"
              style="margin-bottom: 0;"
            >
              Пользователь уже существует
            </v-alert>
          </v-col>

          <v-col :cols="12">
            <v-checkbox
              v-model="showNewsletter"
              label="Получать рассылку новостей и выгодных предложений"
              hide-details
              class="mt-0 pt-0"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="close"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :disabled="email === '' || password === ''"
          :loading="loading"
          @click="addUser"
        >
          Добавить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import generator from 'generate-password';
import { mapFields } from 'vuex-map-fields';
import getMathHeaders from '@/helpers/common/axiosHeaders';

export default {
  name: 'UsersDialogAddUser',

  data() {
    return {
      loading: false,
      userExists: false,
      email: '',
      password: '',
      telegramName: '',
      showNewsletter: true,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      pagination: 'users/pagination',
    }),
    ...mapFields('users', ['topbarSearch']),
  },

  methods: {
    close() {
      this.$store.dispatch('users/closeDialog', 'add');
    },

    generatePassword() {
      const generatedPassword = generator.generate({
        length: 10,
        numbers: true,
      });

      this.password = generatedPassword;

      navigator.clipboard.writeText(generatedPassword);
      this.$store.dispatch('main/alert', {
        color: 'success',
        message: 'Скопировал',
      });
    },

    async addUser() {
      this.loading = true;

      this.userExists = false;
      try {
        const mathHeaders = await getMathHeaders();
        const response = await this.api.post(
          '/auth/register',
          {
            username: this.email,
            password: this.password,
            telegram: this.telegramName,
            newsletter: this.showNewsletter,
          },
          { headers: { ...this.api.headers, ...mathHeaders } },
        );

        if (response.data
         && response.data.error
         && response.data.error.code === 'username_exists') {
          this.userExists = true;
          this.loading = false;
        }

        if (response.status === 200) {
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });

          this.$store.dispatch('users/loadUsers', { query: this.email });
          this.topbarSearch.searchInput = this.email;
          this.close();
        } else {
          throw response;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
