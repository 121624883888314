<template>
  <v-dialog
    :value="dialogs.suspiciousEntrance"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          "Подозрительный вход" (Гео 2FA) {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-data-table
          :items="suspiciousEntrance.suspiciousValues"
          :headers="cols"
          :items-per-page="-1"
          hide-default-footer
          item-key="id"
          height="400"
          fixed-header
          :loading="loading.suspiciousEntrance"
        >
          <!-- ПРОШЕЛ -->
          <template #[`item.login_success`]="{item}">
            <span>
              <v-icon
                v-if="item.login_success"
                color="green accent-4"
              >
                mdi-checkbox-marked
              </v-icon>
              <v-icon
                v-else
                color="red darken-1"
              >
                mdi-close
              </v-icon>
            </span>
          </template>

          <!-- ГЕОЛОКАЦИЯ -->
          <template #[`item.geolocation`]="{item}">
            <span>
              {{ item.country && item.city ? `${item.country}, ${item.city}` : (item.country || item.city) }}
            </span>
          </template>

          <!-- ВРЕМЯ -->
          <template #[`item.created_at`]="{item}">
            <span>
              {{ item.created_at && moment.utc(item.created_at).local().format('DD.MM.YYYY HH:mm') }}
            </span>
          </template>

          <!-- ОС -->
          <template #[`item.os`]="{item}">
            <span>
              {{ item.os || '' }}
            </span>
          </template>

          <!-- ФУТЕР -->
          <template #footer>
            <v-data-footer
              :options="footerOptions"
              :pagination="footerPagination"
              :items-per-page-options="[10, 30, 50]"
              @update:options="updateFooterOptions"
            />
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogSuspiciousEntrance',

  data() {
    return {
      moment,
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      loading: 'users/loading',
      dialogs: 'users/dialogs',
      suspiciousEntrance: 'users/suspiciousEntrance',
    }),

    cols() {
      const cols = [];

      cols.push({
        text: 'Прошел', value: 'login_success', width: 100, sortable: false,
      });
      cols.push({
        text: 'Геолокация', value: 'geolocation', width: 150, sortable: false,
      });
      cols.push({
        text: 'Время', value: 'created_at', width: 150, sortable: true,
      });
      cols.push({
        text: 'ОС', value: 'os', width: 100, sortable: false,
      });

      return cols;
    },

    footerOptions() {
      return {
        page: this.suspiciousEntrance.pagination.currentPage,
        itemsPerPage: this.suspiciousEntrance.pagination.perPage,
      };
    },

    footerPagination() {
      const { pagination } = this.suspiciousEntrance;

      return {
        page: pagination.currentPage,
        itemsPerPage: pagination.perPage,
        pageStart: pagination.from - 1,
        pageStop: pagination.to,
        pageCount: pagination.lastPage,
        itemsLength: pagination.total,
      };
    },
  },

  created() {
    this.$store.dispatch('users/loadSuspiciousEntrance', { id: this.users.forDialog.user.id });
  },

  destroyed() {
    this.$store.dispatch('users/resetUserSuspiciousEntrance');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'suspiciousEntrance');
    },

    updateFooterOptions(options) {
      this.$store.dispatch('users/loadSuspiciousEntrance', {
        page: options.page,
        itemsPerPage: options.itemsPerPage,
        id: this.users.forDialog.user.id,
      });
    },
  },
};
</script>
