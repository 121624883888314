<template>
  <v-dialog
    :value="dialogs.abuse"
    width="600"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Машины с абьюзом от {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5">
        <p>
          Раскодировать MachineId можно <a
            href="https://www.base64decode.org/"
            target="_blank"
          >тут</a>
        </p>

        <v-text-field
          label="Поиск по machineId"
          dense
          solo
          single-line
          hide-details
          clearable
          clear-icon="mdi-close"
          :spellcheck="false"
          @input="search"
        >
          <template #prepend-inner>
            <v-icon size="18">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>

        <v-data-table
          id="affiliateMainTable"
          :items="machines"
          :headers="cols"
          :items-per-page="-1"
          hide-default-footer
          :loading="loadingMachines"
        >
          <template #[`item.actions`]="{ item }">
            <!-- СДЕЛАТЬ ОСНОВНЫМ -->
            <v-tooltip
              top
              nudge-top="20"
            >
              <template #activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  v-on="on"
                  @click="setMainAccount(item.machineId, users.forDialog.user.id)"
                >
                  mdi-account-cowboy-hat
                </v-icon>
              </template>
              <span>Сделать основным {{ users.forDialog.user.username }} для этой машины</span>
            </v-tooltip>

            <!-- ВЕРНУТЬ ОСНОВНОЙ ПО УМОЛЧАНИЮ -->
            <v-tooltip
              top
              nudge-top="20"
            >
              <template #activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  v-on="on"
                  @click="resetMainAccount(item.machineId)"
                >
                  mdi-account-cancel-outline
                </v-icon>
              </template>
              <span>Вернуть основной по умолчанию</span>
            </v-tooltip>

            <!-- СПИСОК АККАУНТОВ -->
            <v-menu
              v-if="true"
              bottom
              offset-y
              :transition="false"
              max-height="200"
            >
              <template #activator="{ on: menu }">
                <v-tooltip
                  top
                  nudge-top="20"
                >
                  <template #activator="{ on: tooltip }">
                    <v-icon
                      small
                      color="primary"
                      v-on="{ ...tooltip, ...menu }"
                      @click="loadAbusers(item.machineId)"
                    >
                      mdi-account-details
                    </v-icon>
                  </template>
                  <span>Список аккаунтов на машине</span>
                </v-tooltip>
              </template>

              <v-skeleton-loader
                v-if="loadingAbusers"
                max-width="300"
                width="200"
                type="list-item@3"
              />
              <v-list>
                <v-list-item
                  v-for="abuser in abusers"
                  :key="`abusers-list-${abuser.id}`"
                >
                  <v-list-item-content>
                    <v-list-item-title :class="{'blue--text' : abuser.username.toUpperCase().includes(users.forDialog.user.username.toUpperCase()) }">
                      {{ abuser.username }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogAbuse',

  data() {
    return {
      machines: [],
      loadedMachines: [],
      abusers: [],
      loadingMachines: false,
      loadingAbusers: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
    }),

    cols() {
      const cols = [];

      cols.push({
        text: 'Действия', value: 'actions', width: 100, sortable: false,
      });
      cols.push({ text: 'Имя машины', value: 'machineName', width: 150 });
      cols.push({ text: 'Основной акк', value: 'main_account.username', width: 150 });
      cols.push({
        text: 'MachineId', value: 'machineId', width: 200, sortable: false,
      });

      return cols;
    },
  },

  created() {
    this.getMachines();
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'abuse');
    },
    async getMachines() {
      this.loadingMachines = true;

      const response = await this.api.get(`/admin/abuser/machines?userId=${this.users.forDialog.user.id}`);
      if (response.status === 200 && response.data) {
        this.machines = response.data;
        this.loadedMachines = response.data;
      }

      this.loadingMachines = false;
    },

    async loadAbusers(machineId) {
      this.abusers = [];
      this.loadingAbusers = true;

      const response = await this.api.get(`/admin/abuser/accounts?machineId=${machineId}`);
      if (response.status === 200 && response.data) {
        this.abusers = response.data;
      }

      this.loadingAbusers = false;
    },

    async setMainAccount(machineId, accountId = null) {
      await this.updateMainAccount(machineId, accountId);
      await this.getMachines();
    },

    async resetMainAccount(machineId) {
      await this.updateMainAccount(machineId, null);
      await this.getMachines();
    },

    async updateMainAccount(machineId, accountId) {
      await this.api.patch('/admin/restrict-machine', {
        machineId,
        userId: accountId,
      });
    },

    search(input) {
      if (!input) {
        this.machines = this.loadedMachines;
        return;
      }

      this.machines = this.machines.filter((machine) => machine.machineId === input);
    },
  },
};
</script>

<style scoped>

</style>
