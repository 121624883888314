<template>
  <v-dialog
    :value="dialogs.payReferal"
    width="500"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Пометить транзакции оплаченными
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <v-col :cols="12">
            <v-date-picker
              v-model="date"
              full-width
              color="light-blue"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          @click="payReferal"
        >
          Выполнить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogPayReferal',

  data() {
    return {
      loading: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
    }),
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'payReferal');
    },

    async payReferal() {
      try {
        this.loading = true;

        const response = await this.api.post('/admin/subscription/pay-referal', {
          refUserId: this.users.forDialog.user.id,
          dueDate: new Date(this.date).getTime() / 1000,
        });
        if (response.status === 200) {
          await this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
          this.closeDialog();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
