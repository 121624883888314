<template>
  <v-dialog
    :value="dialogs.twoFactorByEmailEnableOrDisable"
    width="400"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          {{ users.forDialog.user.twoFactorByEmailEnable ? 'Включить' : 'Выключить' }} Гео 2FA {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        Вы действительно хотите {{ users.forDialog.user.twoFactorByEmailEnable ? 'включить' : 'выключить' }} Гео 2FA
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          @click="handleSave"
        >
          Сменить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'UsersDialogTwoFactorByEmailEnableOrDisable',

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
      pagination: 'users/pagination',
    }),
    ...mapFields('users', ['topbarSearch']),
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'twoFactorByEmailEnableOrDisable');
    },

    handleSave() {
      try {
        this.$store.dispatch('users/changeTwoFactorDisableOrEnable', {
          id: this.users.forDialog.user.id,
          twoFactorDisableOrEnable: this.users.forDialog.user.twoFactorByEmailEnable ? 0 : 1,
        });

        this.$store.dispatch('users/loadUsers', {
          itemsPerPage: this.pagination.perPage,
          query: this.topbarSearch.searchInput,
        });
        this.closeDialog();
      } catch (err) {
        console.log(err); // fixme m
      }
    },
  },
};
</script>
