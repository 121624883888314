<template>
  <v-dialog
    :value="dialogs.disableTwoFactor"
    width="540"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          <pre class="passwordDialogTitle">Выключить двухфакторную
аутентификацию {{ username }}</pre>
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-alert
          color="red"
          type="warning"
        >
          Двуфакторная аутентификация аккаунта пользователя будет отключена. Включить ее обратно через админ-панель будет невозможно.
          Мошенники могут получить доступ к аккаунту пользователя, поэтому убедитесь, что вы следуете инструкции отключения 2FA.
        </v-alert>
        <v-row>
          <!-- Почта пользователя -->
          <v-col :cols="12">
            <v-text-field
              v-model="email"
              label="Почта пользователя"
              dense
              :rules="[emailRules]"
              outlined
              @focus="clearRules"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Отменить
        </v-btn>

        <v-btn
          small
          text
          color="red"
          :loading="loading"
          @click="disable"
        >
          Отключить 2FA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogDisableTwoFactor',

  data() {
    return {
      email: '',
      loading: false,
      emailRules: true,
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      dialogs: 'users/dialogs',
    }),

    hintForUsersInput() {
      return `Начальное значение - ${this.users.forDialog.user.usersLimit}`;
    },

    hintForProfilesInput() {
      return `Начальное значение - ${this.users.forDialog.user.browserProfilesLimit}`;
    },

    username() {
      return this.users.forDialog.user?.username;
    },
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearForDialog');
  },

  methods: {
    showValidationError() {
      this.emailRules = 'Введенная почта не совпадает с почтой пользователя';
    },

    clearRules() {
      this.emailRules = true;
    },

    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'disableTwoFactor');
    },

    async disable() {
      try {
        this.loading = true;

        if (this.email === this.username) {
          const response = await this.api.post('/admin/users/turn-off-two-factory', {
            username: this.email,
          });

          if (response.status === 200) {
            this.closeDialog();
            this.$store.dispatch('users/loadCurrentPage');
            this.$store.dispatch('main/alert', { color: 'success', message: '2FA отключен' });
          }
        } else {
          this.showValidationError();
        }
      } catch (error) {
        console.log(error, 'Error on 2FA disable');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.passwordDialogTitle {
  font-family: "Roboto", sans-serif;
  padding: 14px 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-align: left;

}
</style>
