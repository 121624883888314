<template>
  <v-dialog
    :value="dialogs.changePassword"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Сменить пароль пользователю {{ username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-alert
          color="red"
          type="warning"
        >
          Убедитесь, что вы подтвердили юзера по почте
        </v-alert>
        <v-row>
          <!-- Пароль -->
          <v-col :cols="12">
            <v-text-field
              v-model="password"
              label="Пароль"
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          @click="change"
        >
          Сменить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogChangePassword',

  data() {
    return {
      password: '',
      plan: '',
      usersLimit: '',
      browserProfilesLimit: '',
      subscriptionExpiration: '',
      loading: false,
      daysToAdd: 0,
      amount: 0,
      currency: 'RUB',
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      dialogs: 'users/dialogs',
    }),

    hintForUsersInput() {
      return `Начальное значение - ${this.users.forDialog.user.usersLimit}`;
    },
    hintForProfilesInput() {
      return `Начальное значение - ${this.users.forDialog.user.browserProfilesLimit}`;
    },
  },

  created() {
    this.username = this.users.forDialog.user.username;
    this.plan = this.users.forDialog.user.plan;
    this.usersLimit = this.users.forDialog.user.usersLimit;
    this.browserProfilesLimit = this.users.forDialog.user.browserProfilesLimit;
    this.subscriptionExpiration = this.users.forDialog.user.subscriptionExpiration;
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'changePassword');
    },

    async change() {
      console.log(this.users.forDialog);
      try {
        this.loading = true;
        const data = {
          password: this.password,
          userId: this.users.forDialog.user.id,
        };

        const response = await this.api.post('/admin/users/recover-password', data);

        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('main/alert', { color: 'success', message: 'Изменил' });
        }
      } catch (error) {
        //
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
