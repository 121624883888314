<template>
  <v-dialog
    :value="dialogs.referralCommission"
    width="500"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Реферальная комиссия
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <!-- Пожизненные отчисления -->
          <v-col :cols="12">
            <v-text-field
              v-model="lifetimeDeductions"
              label="Пожизненные отчисления"
              dense
              outlined
              hide-details
              :spellcheck="false"
              :rules="nameRules"
              class="centralize-append-icon"
              type="number"
            />
          </v-col>

          <!-- Временные отчисления -->
          <v-col :cols="12">
            <v-text-field
              v-model="temporaryDeductions"
              label="Временные отчисления"
              dense
              outlined
              hide-details
              :spellcheck="false"
              :rules="nameRules"
              class="centralize-append-icon"
              type="number"
            />
          </v-col>

          <!-- Длительность временных отчислений, в днях -->
          <v-col :cols="12">
            <v-text-field
              v-model="duration"
              label="Длительность временных отчислений, в днях"
              dense
              outlined
              hide-details
              :spellcheck="false"
              class="centralize-append-icon"
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="close"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :disabled="saveButtonDisabled"
          :loading="loading"
          @click="saveReferralCommission"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersDialogReferralCommission',

  data() {
    return {
      loading: false,
      lifetimeDeductions: '',
      temporaryDeductions: '',
      duration: '',
      canSave: false,
      temporaryDeductionsChanged: false,
      nameRules: [
        (v) => !!v || '',
        (v) => (v && v < 100) || '',
        (v) => (v !== '') || '',
      ],
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      dialogs: 'users/dialogs',
    }),

    saveButtonDisabled() {
      if (this.temporaryDeductions > 100 || this.temporaryDeductions === '') {
        return true;
      }
      if (this.lifetimeDeductions > 100 || this.lifetimeDeductions === '') {
        return true;
      }
      return false;
      // return () => this.lifetimeDeductions === '' || this.temporaryDeductions === '' || this.duration === '';
    },
    rules() {
      const rules = [];

      if (this.max) {
        const rule = (v) => (v || '').length <= this.max
                || `A maximum of ${this.max} characters is allowed`;

        rules.push(rule);
      }

      if (!this.allowSpaces) {
        const rule = (v) => (v || '').indexOf(' ') < 0
                || 'No spaces are allowed';

        rules.push(rule);
      }

      if (this.match) {
        const rule = (v) => (!!v && v) === this.match
                || 'Values do not match';

        rules.push(rule);
      }

      return rules;
    },
  },
  mounted() {
    this.lifetimeDeductions = this.users.forDialog.user.refRate;
    this.temporaryDeductions = this.users.forDialog.user.refRateTemporary;
    this.duration = this.users.forDialog.user.refRateDuration > 0 ? this.users.forDialog.user.refRateDuration : '';
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearForDialog');
  },

  methods: {

    close() {
      this.$store.dispatch('users/closeDialog', 'referralCommission');
    },

    async saveReferralCommission() {
      this.loading = true;

      try {
        const response = await this.api.patch('/admin/users/change-ref-rate', {
          userId: this.users.forDialog.user.id,
          refRate: this.lifetimeDeductions,
          temporaryRefRate: this.temporaryDeductions,
          days: this.duration,
        });

        if (response.status === 200) {
          await this.$store.dispatch('users/loadCurrentPage');
          await this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }

        this.close();
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>

<style>
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
