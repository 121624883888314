<template>
  <v-dialog
    :value="dialogs.openDeleteUser"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Удалить {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <span> Аккаунт пользователя будет удален. Пользователь не сможет войти в антик.
          Восстановить аккаунт будет невозможно!<br>
          Вы уверены в своих действия?!<br>
          Для подтверждение введите в поле "удалить {{ users.forDialog.user.username }}" </span>
      </v-card-text>

      <v-card-text>
        <v-text-field
          v-model="deletedMail"
          outlined
        />
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="grey"
          :disabled="isDisableDeleteBtn"
          @click="deleteUser"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'UsersDialogDeleteUser',

  data() {
    return {
      moment,
      deletedMail: '',
      loadingCancelButton: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
    }),

    isDisableDeleteBtn() {
      return this.deletedMail !== `удалить ${this.users.forDialog.user.username}`;
    },
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'openDeleteUser');
    },

    async deleteUser() {
      this.loading = true;

      const response = await this.api.delete(
        `/admin/user?teamId=${this.users.forDialog.user.teamId}`,
      );

      this.$store.dispatch('users/closeDialog', 'openDeleteUser');

      if (response.status === 200) {
        this.$store.dispatch('main/alert', {
          color: 'success',
          message: 'Готово',
        });

        this.$store.dispatch('users/loadUsers', { query: this.email });
        this.topbarSearch.searchInput = this.email;
        this.close();
      } else {
        throw response;
      }
      this.loading = false;
    },
  },
};
</script>
