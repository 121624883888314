<template>
  <v-dialog
    :value="dialogs.showDolphinPay"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Транзакции {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-data-table
          id="dolphin-pay-dialog"
          :items="items"
          :headers="cols"
          :items-per-page="-1"
          hide-default-footer
        >
          <!-- ДАТА -->
          <template #[`item.url`]="{item}">
            <span>
              {{ item.name }} {{ item.url }}
            </span>
          </template>
          <!-- СУММА -->
          <template #[`item.amount`]="{item}">
            {{ item.amount }} / {{ item.paid }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'DolphinPayDialogTransactions',

  data() {
    return {
      moment,
      loadingCancelButton: false,
      items: [],
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
      userTransactions: 'users/userTransactions',
    }),

    cols() {
      const cols = [];

      cols.push({ text: 'URL', value: 'url', width: 200 });
      cols.push({
        text: 'Сумма / оплачено ', value: 'amount', width: 200, sortable: false,
      });

      return cols;
    },
  },
  mounted() {
    console.log(this.dialogs);
    this.getTransactions();
  },

  methods: {

    getTransactions() {
      this.api.get(`/admin/subscription/check-payments?query=${this.users.forDialog.user.username}`).then((res) => {
        this.items = res.data.data;
      });
    },
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'showDolphinPay');
    },

    showSum(item) {
      switch (item.currency.toUpperCase()) {
        case 'RUB':
          return item.sum.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', maximumFractionDigits: 2 });
        default:
          return item.sum.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
      }
    },
    canCancelSelected(item) {
      const $created = moment(item.created_at);
      const $now = moment();
      return $now.diff($created, 'hours') < 24;
    },
    cancelSelected(item) {
      this.loadingCancelButton = true;
      this.api.post('/admin/subscription/cancel-transaction', {
        transactionId: item.id,
      }).then(() => {
        this.$store.dispatch('main/alert', { color: 'success', message: 'Изменил' });
      }).finally(() => {
        this.loadingCancelButton = false;
      });
    },
  },

};
</script>

<style>
#dolphin-pay-dialog .v-data-table__wrapper{
  overflow: hidden !important;
}
</style>
